@import "variables";
@import "reset";
@import "admin";
@import "global";
@import "~ngx-toastr/toastr";
@import "@fancyapps/ui/dist/fancybox.css";

router-outlet ~ * {
    position: absolute;
    height: 100%;
    width: 100%;
}
