@import "variables";

.container {
    max-width: 100%;
    padding: 0 5%;
    width: 1720px;
    margin: 0 auto;
    display: block;

    &.container-small {
        width: 1280px;
    }
}

.mat-tab-body {
    .mat-tab-body-content {
        height: auto;
        overflow: hidden;
    }
}

.mat-dialog-container {
    .mat-dialog-content {
        // overflow: inherit;
    }
}

.institutional {
    .indication {
        padding: 24px;
        background: #f7f7f7;
        margin-bottom: 20px;

        &-item {
            display: block;
            border-bottom: 1px dashed #aaa;

            & + .indication-item {
                margin-top: 16px;
            }

            a {
                text-decoration: underline;
                color: #000;
                text-transform: uppercase;
                position: relative;
                z-index: 2;
                background: #f7f7f7;
                bottom: -4px;
            }
        }
    }

    &-header {
        min-height: 320px;
        background: $color-secondary;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        &__title {
            font-size: 50px;
            color: #ffffff;
        }

        &__tag {
            background: #ffffff;
            border-radius: 25px;
            height: 50px;
            min-width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            color: $text-color-secondary;
            font-size: 18px;
            margin-top: 24px;
        }
    }

    &-content {
        padding: 100px 5% 80px;
        background: #e1e1e1;

        .text {
            max-width: 1024px;
            margin: 0 auto;
            background: #ffffff;
            padding: 24px;
            font-size: 15px;

            &-section {
                margin-bottom: 30px;
                display: block;

                h3 {
                    font-size: 24px;
                    color: $text-color-primary;
                    margin-bottom: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                p {
                    font-size: clamp(12px, 1.25vw, 16px);
                    line-height: 2;
                    font-weight: 400;
                    color: $text-color-primary;

                    & + p {
                        margin-top: 20px;
                    }
                }

                a {
                    color: #000;
                    text-decoration: underline;
                }

                ul {
                    margin: 20px 0;
                    padding-left: 40px;

                    li {
                        font-size: 14px;
                        & + li {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

.btn {
    height: 60px;
    border-radius: 16px;
    padding: 0 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    span {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
    }

    &.btn-default {
        background: $color-primary;
        box-shadow: 0 2px 4px rgba($color: $color-primary, $alpha: 0.2);

        &:hover {
            background: $color-secondary;
            box-shadow: 0 2px 4px rgba($color: $color-secondary, $alpha: 0.2);
        }
    }

    &.btn-secondary {
        background: $color-secondary;
        border-color: $color-secondary;
        box-shadow: 0 2px 4px rgba($color: $color-primary, $alpha: 0.2);

        &:hover {
            background: transparent;
            box-shadow: 0 2px 4px rgba($color: $color-secondary, $alpha: 0.2);
        }

        &.dark {
            &:hover {
                span {
                    color: $color-primary;
                }
            }
        }
    }

    &.btn-small {
        height: 44px;
        padding: 0 16px;
        border-radius: 12px;

        span {
            font-size: 12px;
        }
    }

    &.btn-block {
        display: flex;
        width: 100%;
    }

    &-link {
        display: inline-flex;
        align-items: center;
        padding: 4px;
        border-bottom: 1px solid #ffffff;

        .fas {
            margin-right: 0.5rem;
        }

        span {
            font-size: 14px;
            font-weight: 500;
        }

        &:hover {
            text-decoration: none;
            border-color: $color-secondary;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.btn-excel {
    background-color: #216f42;
    color: #ffffff;
}

body {
    .mat-dialog-container {
        border-radius: 24px;
    }
}

@media screen and (max-width: 768px) {
    .institutional {
        &-header {
            min-height: 200px;
            &__title {
                font-size: 32px;
            }
        }
        &-content {
            padding: 0px;

            .text {
                padding: 24px 5%;
            }
        }
    }
}
