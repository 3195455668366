// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$telmaster-primary: mat.define-palette(
    (
        50: #e4e5e9,
        100: #bbbec7,
        200: #8e92a2,
        300: #61667d,
        400: #3f4661,
        500: #1d2545,
        600: #1a213e,
        700: #151b36,
        800: #11162e,
        900: #0a0d1f,
        A100: #5e74ff,
        A200: #2b48ff,
        A400: #0021f7,
        A700: #001ede,
        contrast: (
            50: #000000,
            100: #000000,
            200: #000000,
            300: #ffffff,
            400: #ffffff,
            500: #ffffff,
            600: #ffffff,
            700: #ffffff,
            800: #ffffff,
            900: #ffffff,
            A100: #ffffff,
            A200: #ffffff,
            A400: #ffffff,
            A700: #ffffff,
        ),
    )
);
$telmaster-accent: mat.define-palette(
    (
        50: #eaf7f6,
        100: #ccebe9,
        200: #aadedb,
        300: #87d0cd,
        400: #6ec6c2,
        500: #54bcb7,
        600: #4db6b0,
        700: #43ada7,
        800: #3aa59f,
        900: #299790,
        A100: #d9fffd,
        A200: #a6fff9,
        A400: #73fff6,
        A700: #59fff5,
        contrast: (
            50: #000000,
            100: #000000,
            200: #000000,
            300: #000000,
            400: #000000,
            500: #000000,
            600: #000000,
            700: #000000,
            800: #000000,
            900: #ffffff,
            A100: #000000,
            A200: #000000,
            A400: #000000,
            A700: #000000,
        ),
    )
);

// The warn palette is optional (defaults to red).
$telmaster-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$telmaster-theme: mat.define-light-theme($telmaster-primary, $telmaster-accent, $telmaster-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($telmaster-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
}
